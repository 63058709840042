import React, {Suspense, useContext, useEffect, useState} from 'react';
import Icon from './../Icon/Icon';
import Lang from '../Lang/Lang';
import {lang, langSiteDir} from '../../common/baseData';
import FooterMenu from './FooterMenu';
import AwardsIcon from './images/awards.jpg';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import Subscribtion from "../Subscribtion/Subscribtion";

function Footer(props) {
  const clientWidth = useContext(ClientWidthContext);
  const isMobile = clientWidth <= 1024;

  const topCategoryList = window.topCategoryList || [];
  const menuFirst = window.footer1 || [];
  const menuSecond = window.footer2 || [];
  const menuThird = window.footer3 || [];

  const wrapClass = `max-w-[1600px] flex flex-col mx-auto p-5 pb-0 desktop:px-5 desktop:pt-14 desktop:pb-16 desktop:flex-row desktop:gap-[86px]`;
  const subClass = `grid gap-4 desktop:grid-cols-4 desktop:grow desktop:justify-items-start`;

  return (
    <>
      <section
        className={`w-full relative px-5 mt-10 mb-5 desktop:py-10 desktop:mb-0 desktop:mt-14 flex justify-center desktop:px-0 desktop:mx-[-20px] desktop:border-y desktop:border-x-0 desktop:border-solid desktop:border-grey-10`}>
        <Subscribtion/>
      </section>
      <footer className="bg-grey-10">
        <div className={wrapClass}>
          <div className={subClass}>
            {
              Array.isArray(topCategoryList) && topCategoryList.length > 0 && (
                <FooterMenu title={Lang.getMessage('topCategories')}
                            type={'topCategoryList'}
                            key={'topCategoryList'}
                            items={topCategoryList.map((category, index) => ({
                              text: category[`name${lang.toUpperCase()}`],
                              link: category.link,
                            }))}/>
              )
            }
            {
              <FooterMenu title={Lang.getMessage('forBuyer')}
                          type={'menuFirst'}
                          key={'menuFirst'}
                          items={menuFirst}/>
            }
            {
              <FooterMenu title={Lang.getMessage('profitably')}
                          type={'menuSecond'}
                          key={'menuSecond'}
                          items={menuSecond}/>
            }
            {
              <FooterMenu title={Lang.getMessage('company')}
                          type={'menuThird'}
                          key={'menuThird'}
                          items={menuThird}/>
            }
          </div>

          <div className="flex flex-col desktop:w-[335px]">
            <div className="flex flex-col mt-4 desktop:mt-0">
              <span className="text-[14px] leading-[21px] text-grey-40 mb-[3px]">
                {Lang.getMessage('contacts')}
              </span>
              <a href="tel:0800204402"
                 className="font-semibold text-[24px] leading-[30px] text-grey-90 mb-[4px] w-fit no-underline">
                0 800 204 402
              </a>
              <span
                className="text-[12px] leading-[18px] text-black font-medium mb-[8px]">
                    {Lang.getMessage('Weekdays')}: з 9 до 22; {Lang.getMessage('Weekend')}: з 10 до 21
                </span>
              <a href={'mailto:info@ti.ua'}
                 className="text-[16px] leading-[24px] text-black font-medium">info@ti.ua</a>
            </div>

            <ul className="flex mt-5 gap-4 h-10 items-center">
              <li>
                <a href={'https://www.facebook.com/tehnoyizhak'}
                   className="flex items-center justify-center w-10 h-10
                   fill-grey-90 hover:fill-blue-primary ">
                  <Icon name={'facebook-icon'} className="h-6 w-6"/>
                </a>
              </li>
              <li>
                <a href={'https://www.instagram.com/tehnoyizhak'}
                   className="flex items-center justify-center w-10 h-10
                   fill-grey-90 hover:fill-blue-primary ">
                  <Icon name={'instagram-icon'} className="h-6 w-6"/>
                </a>
              </li>
              <li>
                <a href={'https://www.tiktok.com/@tehnoyizhak'}
                   className="flex items-center justify-center w-10 h-10
                   fill-grey-90 hover:fill-blue-primary ">
                  <Icon name={'tiktok-icon'} className="h-6 w-6"/>
                </a>
              </li>
              <li>
                <a href={'https://www.youtube.com/@TehnoYizhak'}
                   className="flex items-center justify-center w-10 h-10
                   fill-grey-90 hover:fill-blue-primary ">
                  <Icon name={'youtube-icon'} className="h-10 w-10"/>
                </a>
              </li>
              <li>
                <a href={'https://t.ly/y4cVL'}
                   className="flex items-center justify-center w-10 h-10
                   fill-grey-90 hover:fill-blue-primary ">
                  <Icon name={'telegram-icon'} className="h-6 w-6"/>
                </a>
              </li>
            </ul>

            <div className="flex mt-5 justify-between items-center">
              <div className="flex flex-col max-w-[calc(46%)] shrink-0">
                <a href={'https://ti.ua/news/retail_awards_2019/'}>
                  <img src={AwardsIcon}
                       className="h-[18px] max-w-full"
                       loading={'lazy'}
                       alt={'awards'} width={'87px'} height={'18px'}/>
                </a>
                <span
                  className="text-[12px] leading-[18px] text-grey-90 font-medium">
                  {Lang.getMessage('awards')}
                </span>
              </div>
              <span>
              {
                !isMobile && (
                  <Icon name={'google-service'} className="w-[138px] h-[45px]"/>
                )
              }
            </span>
            </div>
          </div>
        </div>
        <div
          className="max-w-[1600px] mx-auto flex mt-5 border-0 border-t border-solid border-grey-20 p-5 desktop:gap-5">
          <div
            className="flex flex-col gap-1 basis-2/3 desktop:flex-row desktop:basis-auto desktop:gap-5">
            <a href={`${langSiteDir}privacy/`}
               className="text-[12px] leading-[18px] desktop:text-[14px] desktop:leading-[21px] text-grey-80 font-medium">
              {Lang.getMessage('privacy')}
            </a>
            <a href={`${langSiteDir}public-offer/`}
               className="text-[12px] leading-[18px] desktop:text-[14px] desktop:leading-[21px] text-grey-80 font-medium">
              {Lang.getMessage('publicOffer')}</a>
          </div>
          <div
            className="basis-1/3 flex flex-col gap-1 desktop:flex-row desktop:grow desktop:justify-between">
            <a href={`${langSiteDir}sitemap.html`}
               className="text-[12px] leading-[18px] desktop:text-[14px] desktop:leading-[21px] text-grey-80 font-medium text-right">
              Sitemap
            </a>
            <span
              className="text-[12px] leading-[18px] desktop:text-[14px] desktop:leading-[21px] text-grey-80 font-medium text-right">
                © ti.ua, 2014-2025
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;