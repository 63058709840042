import React, {useEffect, useState} from 'react';
import Lang from '../../Lang/Lang';
import SubSection from './SubSection';

function SubSections(props) {
  const {
    subSections,
    allSections,
    popularBlockUsed,
    sectionTreeHeight,
  } = {...props};

  // limit items count in one column
  const [columnLimit, setColumnLimit] = useState(null);

  const columnLimitDefault = 13;
  const columnsLimit = popularBlockUsed ? 4 : 5;
  const columnItemHeight = 34; // column item height in px
  const subSectionsLimit = 15;

  const [subSectionsChunks, setSubSectionsChunks] = useState([]);

  // calculation of columnLimit items
  useEffect(() => {
    if (sectionTreeHeight) {
      let tmpColumnLimit = Number(sectionTreeHeight / columnItemHeight);
      if (!isNaN(tmpColumnLimit)) setColumnLimit(tmpColumnLimit);
      else setColumnLimit(columnLimitDefault);
    } else {
      setColumnLimit(columnLimitDefault);
    }
  }, [sectionTreeHeight]);

  // calculation of subsections chunks
  useEffect(() => {
    if (!Array.isArray(subSections) || !subSections.length ||
      !allSections.length)
      setSubSectionsChunks([]);
    else {
      let sub_sections_chunks = [];
      let sub_sections_chunk = [];

      for (let section of subSections) {
        let sub_sections = getSubSections(section);

        // slice sections for one column
        if (sub_sections.length > columnLimit) {

          // check current chunk
          if (sub_sections_chunk.length) {
            // save current chunk
            sub_sections_chunks.push(sub_sections_chunk);
            // check chunks limit
            if (sub_sections_chunks.length >= columnsLimit) break;
            sub_sections_chunk = [];
          }

          // main section (depth level 2)
          sub_sections_chunk.push(section);

          let counter = 0;
          let sectionCounter = 0;

          for (let sub_section of sub_sections) {
            if (sub_section.hasOwnProperty('hideLink') &&
              sub_section.hideLink) {
              sub_sections_chunk.push(sub_section);
              sectionCounter = 0;
            } else sub_sections_chunk.push(sub_section);

            counter++;
            sectionCounter++;

            if (counter >= columnLimit || sectionCounter >=
              subSectionsLimit) break;
          }


          // save current chunk
          sub_sections_chunks.push(sub_sections_chunk);
          // check chunks limit
          if (sub_sections_chunks.length >= columnsLimit) break;
          sub_sections_chunk = [];

        } else {

          if (sub_sections_chunk.length + sub_sections.length > columnLimit) {
            // save current chunk
            sub_sections_chunks.push(sub_sections_chunk);
            // check chunks limit
            if (sub_sections_chunks.length >= columnsLimit) break;
            sub_sections_chunk = [];
          }

          // main section (depth level 2)
          sub_sections_chunk.push(section);

          // sub section (depth level 3)
          for (let sub_section of sub_sections) {
            sub_sections_chunk.push(sub_section);
          }
        }
      }

      // save last chunk
      if (sub_sections_chunk.length &&
        sub_sections_chunks.length < columnsLimit)
        sub_sections_chunks.push(sub_sections_chunk);

      setSubSectionsChunks(sub_sections_chunks);
    }
  }, [columnLimit, subSections]);

  // find and return subsections for section
  function getSubSections(section) {
    let sub_sections = [];

    for (let sub_section of allSections) {
      if (!sub_section.hasOwnProperty('parentId') || sub_section.parentId ===
        null ||
        Number(sub_section.parentId) !== Number(section.itemId)) continue;

      sub_sections.push(sub_section);
    }

    return sub_sections;
  }

  return (
    <>
      {
        subSectionsChunks.map((chunk, chunkIndex) => (
          <ul className={`flex flex-col  
          ${subSectionsChunks.length >= 3 ? 'basis-1/4' : 'basis-1/3'}`}
              key={`submenu-column-${chunkIndex}`}>
            {
              Array.isArray(chunk) && chunk.length > 0 && (
                chunk.map((item, index) => (
                  <SubSection key={`${chunkIndex}-column-${index}`}
                              first={index === 0}
                              {...item}/>
                ))
              )
            }
          </ul>
        ))
      }
    </>
  );
}

export default SubSections;