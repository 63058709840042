import React from 'react';

function SubSection(props) {
  const {
    itemId,
    title,
    link,
    newSection,
    depthLevel,
    hideLink,
    first,
    virtualTitle,
  } = {...props};

  const titleClass = `flex gap-2 text-[18px] leading-[27px] text-blue-primary`;
  const linkClass = `flex gap-2 text-[14px] leading-[21px] text-grey-90 mt-1.5`;

  const itemClass = Number(depthLevel) === 2 || virtualTitle ?
    titleClass : linkClass;

  return (
    <li className={`${Number(depthLevel) === 2 && !first ? 'mt-3' : ''}`}>
      {
        hideLink ?
          <span className={itemClass} key={itemId}>{title}</span> :
          <a href={link} className={itemClass} key={itemId}>
            <span className={`hover:underline hover:underline-offset-2 hover:decoration-blue-primary hover:text-blue-primary`}>{title}</span>
            {
              newSection && (
                <span className="self-start text-[10px] leading-[15px] font-medium italic text-red-mistake">new</span>
              )
            }
          </a>
      }
    </li>
  );
}

export default SubSection;